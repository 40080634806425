.hello-world {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 3em;
  display: flex;
}

@media only screen and (width >= 550px) {
  .hello-world {
    font-size: 5em;
  }
}

@media only screen and (width >= 650px) {
  .hello-world {
    font-size: 10em;
  }
}
/*# sourceMappingURL=index.854c2dd3.css.map */
